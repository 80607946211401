.section-container {
  width: 90%;
  /* height: 80vh; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.section-title {
  width: 100%;
  font-size: 2rem;
  /* color: rgb(205, 199, 188); */
  /* color: #e98007; */
  color: white;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 15px;
}

.section-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 5vh; */
  color: white;
}


@media (max-width: 767.98px) {
  .section-container {
    height: auto;
  }
  .section-title {
    text-align: center;
  }
  .section-content {
    flex-direction: column;
  }
}