.Contact {
  height: 80vh;
}

.Contact-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.Contact-text {
  align-self: flex-start;
}

.Contact-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Contact-link {
  margin: 5px;
}

@media (max-width: 767.98px) {
  .Contact-text {
    font-size: 1em;
    text-align: center;
  }
}
