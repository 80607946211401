.NavBar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  height: 10vh;
  z-index: 100;
}

.NavBar-content {
  background: #071e2e;
  height: 95%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 3%;
  padding-right: 3%;
}

.NavBar-button {
  color: white;
  margin-left: 1vw;
  margin-right: 1vw;
  font-size: 1.5rem;
  text-align: center;
}

.NavBar-button-home {
  margin-right: auto;
}

.NavBar-dropdown {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* position: relative; */
}

.NavBar-dropdown-content {
  position: relative;
  background-color: black;
  z-index: 200;
}

.NavBar-button-resume {
  border:2px solid #7df9ff;
  border-radius: 3px;
  padding-left: 1%;
  padding-right: 1%;
}

.NavBar-button-resume:hover {
  text-decoration: none;
  color: black;
  background: #7df9ff;
}

.NavBar-bottom-line {
  background: #7df9ff;
  height: 5%;
}

@media (max-width: 767.98px) {
  .NavBar {
    height: 7vh;
  }
  .NavBar-content {
    justify-content: center;
  }
  .NavBar-button-home {
    margin-right: 0;
  }
  .NavBar-button {
    font-size: 3.5vw;
  }
}
