.Portfolio {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  
  .Portfolio-container {
    height: auto;
  }
  
  .Portfolio-content {
    flex-direction: column;
  }
  
  .Portfolio-title {
    color: rgb(205, 199, 188);
  }
  
  .Portfolio-card {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
  }
  
  .Portfolio-card-image-div {
    width: 30%;
  }
  
  .Portfolio-card-image {
    width: 100%;
    height: auto;
  }
  
  .Portfolio-card-content {
    width: 70%;
    margin-left: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: start;
  }
  
  .Portfolio-card-title {
    text-align: start;
  }
  
  .Portfolio-card-etc {
    text-align: start;
    margin-bottom: 0;
  }
  
  .Portfolio-card-text {
    text-align: start;
  }
  .Portfolio-card-keyword {
    color: #7df9ff
  }
  
  .Portfolio-link-button {
    color: white;
    border: 1px solid #7df9ff;
    border-radius: 3px;
    padding: 5px;
    margin-right: 10px;
  }
  
  .Portfolio-link-button:hover {
    text-decoration: none;
    color: black;
    background: #7df9ff;
  }
  
  .Portfolio-card-title-divider {
    width: 100%;
    height: 1px;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #7df9ff;
  }
  
  .Portfolio-card-divider {
    width: 100%;
    height: 2px;
    background-color: #7df9ff;
  }
  
  .Portfolio-card-etc-highlight {
    color: #7df9ff;
  }
  
  @media (max-width: 767.98px) {
    .Portfolio-card {
      flex-direction: column;
      align-items: center;
    }
  
    .Portfolio-card-image-div {
      width: 50%;
    }
  
    .Portfolio-card-content {
      width: 100%;
    }
  
    .Portfolio-card-title {
      width: 100%;
      text-align: center;
      margin-top: 2vw;
      margin-bottom: 1.5vw;
    }
  
    .Portfolio-card-divider {
      margin-bottom: 1rem;
    }
  }