.Home {
  width: 100%;
  height: 100vh;
}

.Home-gradient-background {
  background: radial-gradient(circle, #2980b9, #2c3e50);
  background-size: 400% 400%;

  -webkit-animation: GradientAnimation 3s ease infinite;
  -moz-animation: GradientAnimation 3s ease infinite;
  animation: GradientAnimation 3s ease infinite;

  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

@-webkit-keyframes GradientAnimation {
  0% {
    background-position: 0% 54%;
  }
  50% {
    background-position: 100% 47%;
  }
  100% {
    background-position: 0% 54%;
  }
}

@-moz-keyframes GradientAnimation {
  0% {
    background-position: 0% 54%;
  }
  50% {
    background-position: 100% 47%;
  }
  100% {
    background-position: 0% 54%;
  }
}

@keyframes GradientAnimation {
  0% {
    background-position: 0% 54%;
  }
  50% {
    background-position: 100% 47%;
  }
  100% {
    background-position: 0% 54%;
  }
}

.Home-radial-background {
  background: radial-gradient(circle, #000000, #00000000, #000000);
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: white;
  text-align: center;
}

.Home-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Home-type-writer {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.15em solid #7df9ff; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 2s steps(30, end), blink-caret 0.75s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #7df9ff;
  }
}

.Home-text {
  align-self: center;
  margin: 0;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 1.5em;
}

.Home-button {
  padding: 0;
}
