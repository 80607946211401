.Arrow {
  width: 100%;
  height: 9vh;
  padding-bottom: 1vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.Arrow-button {
  padding: 0;
  margin: 0;
  height: 90%;
}
