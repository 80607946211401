.About-content {
  align-items: flex-start !important;
}

.About-profile {
  padding-left: 0;
  height: 100%;
}

.About-profile-name {
  color: white;
  text-align: center;
}

.About-info > h3 {
  font-size: 4vh;
}

/* .About-info > h4 {
  margin-bottom: 5px;
} */

.About-seperator {
  border-top: 1px solid #7df9ff;
  width: 100%;
  height: 5px;
  margin-top: 15px;
  margin-bottom: 5px;
}

.About-skills {
  color: white;
  padding-left: 0;
}

.About-skills-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
}

.About-skills-row > h4 {
  margin-bottom: 0 !important;
  font-size: 3vh;
}

.About-others {
  font-size: 3vh;
}

.About-bar-border {
  border: 2px solid #7df9ff;
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin-left: auto;
}

.About-bar-filled {
  background: #7df9ff;
  height: 100%;
}

.About-bar-percent {
  margin: 0;
  margin-left: auto;
}

@media (max-width: 767.98px) {
  .About-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .About-info > h3 {
    font-size: 1em;
  }
  .About-skills-row > h4 {
    font-size: 1em;
  }
  .About-others {
    font-size: 1em;
  }
}
